import BurstPuckerImageTitles from "@/components/common/burstPuckerImageTitles";
import Image from "next/image";
import Link from "next/link";

//Partnership & Collaborations
import BitVenus from "@/assets/images/icons/featureIn/Collaborations/BitVenus.webp";
import BYBITs from "@/assets/images/icons/featureIn/Collaborations/BYBIT.webp";
import CERTIK from "@/assets/images/icons/featureIn/Collaborations/CERTIK.webp";
import CyberScope from "@/assets/images/icons/featureIn/Collaborations/CyberScope.webp";
import GateIo from "@/assets/images/icons/featureIn/Collaborations/Gate.io.webp";
import MEXC from "@/assets/images/icons/featureIn/Collaborations/MEXC.webp";
import NDSS from "@/assets/images/icons/featureIn/Collaborations/NDSS.webp";
import Toobit from "@/assets/images/icons/featureIn/Collaborations/Toobit.webp";

import Biconomy from "@/assets/images/icons/featureIn/Collaborations/Biconomy.webp";
import Bigone from "@/assets/images/icons/featureIn/Collaborations/Bigone.webp";
import Sandbox from "@/assets/images/icons/featureIn/Collaborations/Group.webp";
import Tapbit from "@/assets/images/icons/featureIn/Collaborations/Tapbit.webp";

import wollongong from "@/assets/images/icons/featureIn/Collaborations/wollongong.webp";
import artverse1 from "@/assets/images/icons/trusted-partners/ArtVerse_Logo_B.webp";
import artverse2 from "@/assets/images/icons/trusted-partners/ArtVerse_Logo_Type_B.webp";
import metaTheory from "@/assets/images/icons/trusted-partners/meta_theory.webp";

import fiveire from "@/assets/images/icons/featureIn/Collaborations/5ire.webp";
import altura from "@/assets/images/icons/featureIn/Collaborations/altura.png";
import another from "@/assets/images/icons/featureIn/Collaborations/another.webp";
import binance from "@/assets/images/icons/featureIn/Collaborations/binance.webp";
import blocx from "@/assets/images/icons/featureIn/Collaborations/blocx.webp";

function PartnershipCollaborationsSection({ homeStyle }) {
  return (
    <>
      <div className={`center featurein ${homeStyle["featurein"]}`}>
        <div className="featurein__header ">
          <div
            className={`hot__title h3 burst center ${homeStyle["home__featureIn_center"]} 
          ${homeStyle["home__featureIn_margin-r"]}`}
          >
            <BurstPuckerImageTitles />
            <span
              className={`hot__Partnership ${homeStyle["home__featureIn_width"]} `}
            >
              Partnership and Collaborations
            </span>
            <BurstPuckerImageTitles />
          </div>
        </div>
        <div className="featurein__container center">
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.uow.edu.au/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={120}
                  height={61}
                  loading="lazy"
                  src={wollongong}
                  alt="wollongong"
                  quality={100}
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              University of Wollongong is one of the world&apos;s top modern
              universities
            </div>
          </div>
          {/*<div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.uow.edu.au/smart/telstra-uow-hub/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={120}
                  height={79}
                  loading="lazy"
                  src={smart}
                  alt="smart"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              {" "}
              An international leader in applied infrastructure research
            </div>
          </div> */}
          {/* Metatheory studio */}
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.artsy.net/partner/artverse"
                target="_blank"
                className="featurein__item"
              >
                <div className="iconArtverse">
                  <Image
                    width={40}
                    height={40}
                    loading="lazy"
                    src={artverse1}
                    alt="artverse"
                    style={{
                      objectFit: "contain",
                    }}
                  />
                  <Image
                    width={189}
                    height={57}
                    loading="lazy"
                    src={artverse2}
                    alt="artverse "
                    style={{
                      objectFit: "contain",
                    }}
                  />
                </div>
              </Link>
            </div>
            <div className="Body">
              A cultural space where digital art and community come together
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.binance.com/en/binancewallet"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={134}
                  height={45}
                  loading="lazy"
                  quality={100}
                  src={binance}
                  alt="victus global"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              {" "}
              keyless, seedless, multi-chain, semi-custody wallet designed for
              DeFi
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://ndss.org/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={80}
                  height={22}
                  loading="lazy"
                  src={NDSS}
                  alt="NDSS"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              NDSS empowers individuals with Down syndrome and their families
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.mexc.com/tokens/PHOTO"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={158}
                  height={33}
                  loading="lazy"
                  src={MEXC}
                  alt="MEXC"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              MEXC provides fast and secure digital asset trading services
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://announcements.bybit.com/article/bybit-web3-x-seed-photo-exclusive-launch-of-two-award-winning-photographic-nft-collection-bltda2e2e914ea23aa1/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={117}
                  height={40}
                  loading="lazy"
                  src={BYBITs}
                  alt="BYBIT"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Crypto trading experience elevated. Buy, sell, trade BTC,
              alt-coins & NFTs.
            </div>
          </div>

          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://skynet.certik.com/projects/seed-photo"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={163}
                  height={40}
                  loading="lazy"
                  src={CERTIK}
                  alt="CERTIK"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              {" "}
              Blockchain and DeFi ranking platform for analysis and monitoring
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.gate.io/web3/dapps/dapp-detail?id=1511"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={126}
                  height={40}
                  loading="lazy"
                  src={GateIo}
                  alt="GateIo"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Leading cryptocurrency exchange with over 1700+ cryptocurrencies
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.sandbox.game/en/experiences/lens-gallery-by-seed-photo/cefb769f-dd44-4999-9333-2186c4d6c15d/page/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={127}
                  height={50}
                  loading="lazy"
                  src={Sandbox}
                  alt="Sandbox"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">An immersive online gaming platform</div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.bvox.com/en-US/exchange/SEED/USDT"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={170}
                  height={39}
                  loading="lazy"
                  src={BitVenus}
                  alt="BitVenus"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              BitVenus: Enjoy safe and stable trading for BTC, ETH, and more
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.biconomy.com/exchange/SEED_USDT"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={193}
                  height={60}
                  loading="lazy"
                  src={Biconomy}
                  alt="CERTIK"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              {" "}
              Operates the worlds biggest Bitcoin exchange and Altcoins
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.toobit.com/en-US/spot/SEED_USDT"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={128}
                  height={23}
                  loading="lazy"
                  src={Toobit}
                  alt="Toobit"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Toobit is the easiest, safest, and most rewarding crypto trading
              platform
            </div>
          </div>
        </div>

        <div className="featurein__container center">
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.cyberscope.io/audits/1-seed"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={170}
                  height={53}
                  loading="lazy"
                  quality={100}
                  src={CyberScope}
                  alt="CyberScope"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Providing smart contract audit and cybersecurity services
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.tapbit.com/spot/exchange/SEED_USDT"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={126}
                  height={44}
                  loading="lazy"
                  src={Tapbit}
                  alt="BYBIT"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Where you can buy and sell Bitcoin and other top crypto currencies
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://big.one/en/trade/SEED-USDT"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={125}
                  height={47}
                  loading="lazy"
                  src={Bigone}
                  alt="NDSS"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              A trading platform you can exchange and store cryptocurrencies
            </div>
          </div>

          {/* Metatheory studio */}
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://metatheory.studio/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={160}
                  height={44}
                  loading="lazy"
                  src={metaTheory}
                  alt="Metatheory studio"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              A premier photography contest, recognises and rewards excellence.
            </div>
          </div>
        </div>

        <div className="featurein__container center">
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://5ire.org/home"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={103}
                  height={30}
                  loading="lazy"
                  quality={100}
                  src={fiveire}
                  alt="fiveire"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              A blockchain network promoting ESG-friendly practices.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://altura.com/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={130}
                  height={50}
                  loading="lazy"
                  src={altura}
                  alt="altura"
                  style={{
                    objectFit: "contain",
                    marginTop: "14px",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              A Web3 gaming platform for smart NFTs and blockchain assets.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://another-1.io/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={150}
                  height={50}
                  loading="lazy"
                  src={another}
                  alt="another"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              A Web3 platform linking digital and physical luxury via
              blockchain.
            </div>
          </div>

          {/* Metatheory studio */}
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://blocx.tech/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={127}
                  height={50}
                  loading="lazy"
                  src={blocx}
                  alt="blocx"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              A secure hub for wallets, protection, and blockchain earnings
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PartnershipCollaborationsSection;
